import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Trans } from 'react-i18next';

import { useAppSelector } from '@common/hooks/redux';
import { useCelebrations } from '@common/hooks/use-celebrations';
import Spinner from '@common/components/spinner';
import { ProfileImagesGroup } from '@common/components/profile-image';

import CelebrationsModal from './celebrations-modal';
import { Celebration } from './types';
import { network } from '@modules/core/selectors/logged-user';

export const CelebrationsWidget = () => {
  const history = useHistory();
  const selectedNetwork = useAppSelector(network);

  const [showModal, setShowModal] = useState<boolean>(false);
  const { celebrations, loading } = useCelebrations({ network_ids: [selectedNetwork.id] });

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleClick = useCallback((user: Celebration) => {
    history.push(`/networks/${selectedNetwork.id}/users/${user.id}/about`);
  }, [history, selectedNetwork.id]);

  return (
    <>
      <div className="balloon">
        <h5 className="balloon__title">
          <img
            className="tw-mr-2 tw-h-5 tw-relative tw-top-0 tw-mt-[-3px] tw-mb-[-3px]"
            src="/static/images/social/star-sparkle.svg?url"
            alt="cake"
          />
          <span><Trans i18nKey="social:celebrations" /></span>
          <div className="pull-right">
            <a onClick={toggleModal}>
              <Trans i18nKey="social:view_birthdays" />
            </a>
          </div>
        </h5>

        {loading ? (
          <Spinner centered />
        ) : (
          <>
            {celebrations.length > 0 ? (
              <p>
                <Trans
                  i18nKey="social:celebrations_summary"
                  values={{ count: celebrations.length > 1 ? `${celebrations.length} colleagues` : '1 colleague' }}
                />
              </p>
            ) : (
              <p className="tw-text-gray-custom-700 tw-text-center tw-mt-[30px]">
                <Trans i18nKey="social:no_celebrations_today" />
              </p>
            )}

            {celebrations.length > 0 && (
              <div className="tw-flex tw-flex-row tw-gap-2">
                <ProfileImagesGroup
                  users={celebrations}
                  size={32}
                  limit={10}
                  showTooltip
                  tooltipKey="tooltip"
                  onShowAll={toggleModal}
                  onClick={handleClick}
                />
              </div>
            )}
          </>
        )}
      </div>
      {showModal && (
        <CelebrationsModal open={showModal} onClose={toggleModal} networkIds={[selectedNetwork.id]} />
      )}
    </>
  );
};
